import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../dist/css/style.css"
const Story = () => {
  return (
    <Layout>
      <SEO title="Story" />
      <h1>Story</h1>
    </Layout>
  )
}

export default Story
